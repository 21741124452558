import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Preview from '@mui/icons-material/Preview'
import Box from '@mui/material/Box'

import Top from './top_diamond_drop.png'
import Bottom from './bottom_diamond_drop.png'

import { AuthContext } from '../contexts/authContext'
import { useTheme } from '@material-ui/core'
import { Close } from '@mui/icons-material'

export default function PreviewDialog({ formValues }) {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const auth = useContext(AuthContext)
  let name = null
  if (typeof auth.attrInfo !== 'undefined') {
    for (var i = 0; i < auth.attrInfo.length; i++) {
      if (auth.attrInfo[i].Name == 'name') {
        name = auth.attrInfo[i].Value
      }
    }
  } else {
    name = 'Your Name'
  }

  return (
    <div>
      <Button endIcon={<Preview />} color="primary" variant="outlined" onClick={handleClickOpen}>
        Preview
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="preview-dialog-title"
        aria-describedby="preview-dialog-description">
        <DialogTitle id="preview-dialog-title" variant="h6" align="center">
          Your Preview
        </DialogTitle>
        <DialogContent align="center">
          <img src={Top} alt="top" width="60%" align="center" />
          <Box sx={{ border: '12px solid', borderColor: 'rgb(243, 243, 243)', width: '80%' }}>
            <Box sx={{ border: '2.5px solid', borderColor: 'rgb(192, 0, 60)' }}>
              <Box sx={{ border: '12px solid', borderColor: 'rgb(243, 243, 243)' }}>
                <DialogContentText id="preview-dialog-description" variant="h6" width="100%" color="black">
                  {formValues.toName ? formValues.toName : '[Recipient]'},
                  <br></br>
                  {formValues.submissionText}
                  <br></br>
                  -{name}
                </DialogContentText>
              </Box>
            </Box>
          </Box>
          <img src={Bottom} alt="bottom" width="60%" align="center" />
        </DialogContent>
        <Box sx={{ position: 'sticky', marginBottom: '20px', marginTop: '20px', textAlign: 'center' }}>
          <Button endIcon={<Close />} color="primary" variant="outlined" type="button" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Box>
      </Dialog>
    </div>
  )
}
