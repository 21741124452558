import React, { useState } from 'react'

import {
  Dialog,
  DialogTitle,
  TextField,
  Button,
  Grid,
  CircularProgress,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core'
import { useValidEmail, useValidName } from '../hooks/useAuthHooks'

export const Email: React.FunctionComponent<{ emailIsValid: boolean; setEmail: (_: string) => void }> = ({
  emailIsValid,
  setEmail,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={emailIsValid ? 'Email' : 'Invalid Email'}
      error={!emailIsValid}
      margin="dense"
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEmail(evt.target.value)
      }}
    />
  )
}

export const Password: React.FunctionComponent<{
  label: string
  passwordIsValid: boolean
  setPassword: (_: string) => void
}> = ({ label, passwordIsValid, setPassword }) => {
  return (
    <TextField
      fullWidth
      name="password"
      type="password"
      variant="outlined"
      label={passwordIsValid ? label : 'Minimum 8 characters'}
      error={!passwordIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPassword(evt.target.value)
      }}
    />
  )
}

export const Username: React.FunctionComponent<{ usernameIsValid: boolean; setUsername: (_: string) => void }> = ({
  usernameIsValid,
  setUsername,
}) => {
  return (
    <TextField
      fullWidth
      name="username"
      variant="outlined"
      label={usernameIsValid ? 'Email' : 'Minimum 8 characters'}
      error={!usernameIsValid}
      margin="dense"
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUsername(evt.target.value)
      }}
    />
  )
}

export const Name: React.FunctionComponent<{  nameIsValid: boolean; setName: (_: string) => void; helperText: string }> = ({
  nameIsValid,
  setName,
  helperText
}) => {
  return (
    <TextField
      fullWidth
      name="name"
      variant="outlined"
      label={nameIsValid ? 'Name' : 'Minimum 8 characters'}
      error={!nameIsValid}
      margin="dense"
      autoComplete='name'
      helperText={helperText}
      FormHelperTextProps={{variant: 'outlined'}}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setName(evt.target.value)
      }}
    />
  )
}

export const Code: React.FunctionComponent<{ codeIsValid: boolean; setCode: (_: string) => void }> = ({
  codeIsValid,
  setCode,
}) => {
  return (
    <TextField
      fullWidth
      name="code"
      variant="outlined"
      label={codeIsValid ? 'Code' : 'Minimum 6 characters'}
      error={!codeIsValid}
      margin="dense"
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCode(evt.target.value)
      }}
    />
  )
}

export const SupportPopup: React.FunctionComponent<{
  open: boolean
  handleClose: () => void
  status: number
  setStatus: (_: number) => void
  openSnackBar: () => void
}> = ({ open, handleClose, status, setStatus, openSnackBar }) => {
  const { name, setName, nameIsValid } = useValidName('')
  const { email, setEmail, emailIsValid } = useValidEmail('')
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClear = () => {
    setEmail('')
    setName('')
    setDescription('')
  }

  const createTicket = async (values: any) => {
    setLoading(true)
    try {
      const result = await fetch('https://recognition.humanexventures.com/support', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Content-Type,access-control-allow-origin, access-control-allow-headers',
        },
      })

      const { data } = await result.json()
      handleClose()
      handleClear()
      setStatus(200)
      openSnackBar()
      setLoading(false)
      return data
    } catch (error) {
      setStatus(-1)
      setLoading(false)
      openSnackBar()
    }
  }

  const handleSubmit = () => {
    const values = {
      firstName: name.split(' ')[0],
      lastName: name.split(' ')[1],
      email: email,
      subject: 'Recognition Support',
      description: description,
      cf_url: window.location.href,
    }
    const data = createTicket(values)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      {loading ? (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <DialogTitle>Contact Support</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter your information and a description of your problem to contact the Technology team, you will recieve
              an email response within a few minutes.
            </DialogContentText>
            <Name nameIsValid={nameIsValid} helperText='Your name' setName={setName} />
            <Email emailIsValid={emailIsValid} setEmail={setEmail} />
            <TextField
              type="submissionText"
              label="Description of your problem..."
              name="submissionText"
              variant="outlined"
              margin="dense"
              fullWidth
              multiline
              maxRows={6}
              minRows={4}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="outlined" onClick={handleSubmit}>
              Contact Support
            </Button>
            <Button color="secondary" variant="contained" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
