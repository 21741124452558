import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Button, Typography, Paper, CircularProgress, Snackbar, Icon } from '@material-ui/core'
import { Help, Login } from '@mui/icons-material'
import Alert from '@mui/material/Alert'

import { useValidPassword, useValidUsername } from '../../hooks/useAuthHooks'
import { Password, Username, SupportPopup } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'

import Logo from '../White_humanex.png'

const useStyles = makeStyles({
  root: {
    height: '100vh',
    backgroundColor: '#444',
  },
  top: {
    padding: '0 2vw',
    marginBottom: '0',
  },
  hover: {
    padding: '2px 5px',
    borderRadius: '4px',
    transition: 'ease-in-out 300ms',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
})

const SignIn: React.FunctionComponent<{}> = () => {
  const classes = useStyles()

  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [error, setError] = useState('')
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState(0)
  const [loading, setLoading] = useState(false)

  const [openSnackBar, setOpenSnackBar] = useState(false)
  const handleOpenSB = () => setOpenSnackBar(true)
  const handleCloseSB = () => setOpenSnackBar(false)

  const isValid = !usernameIsValid || username.length === 0 || !passwordIsValid || password.length === 0

  const history = useHistory()

  const signUpIcon = <Icon component={Login} />
  const helpIcon = <Icon component={Help} />

  const authContext = useContext(AuthContext)

  const signInClicked = async () => {
    setLoading(true)
    try {
      await authContext.signInWithEmail(username, password)
      setLoading(false)
      history.push('home')
    } catch (err: any) {
      setLoading(false)
      if (err.code === 'UserNotConfirmedException') {
        history.push('verify')
      } else {
        setError(err.message)
      }
    }
  }

  const passwordResetClicked = async () => {
    history.push('requestcode')
  }

  return (
    <Grid
      className={classes.root}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ width: '100%' }}>
      <Grid className={classes.top} xs={12} container direction="row" justifyContent="center" alignItems="center" item>
        <img src={Logo} alt="logo" height="75" />
        <Typography variant="h2" style={{ marginLeft: '15px', color: 'white' }}>
          Recognition
        </Typography>
      </Grid>
      <Grid xs={11} sm={6} lg={4} container direction="row" justifyContent="center" alignItems="center" item>
        <Paper style={{ width: '100%', padding: 32 }}>
          {loading ? (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              {/* Title */}
              <Box m={2}>
                <Typography variant="h4">Sign in</Typography>
              </Box>

              {/* Sign In Form */}
              <Box width="80%" m={1}>
                {/* <Email emailIsValid={emailIsValid} setEmail={setEmail} /> */}
                <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />{' '}
              </Box>
              <Box width="80%" m={1}>
                <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  <Box onClick={passwordResetClicked} mt={2}>
                    <Typography className={classes.hover} variant="body2">
                      Forgot Password?
                    </Typography>
                  </Box>
                </Grid>
              </Box>

              {/* Error */}
              <Box mt={2}>
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              </Box>

              {/* Buttons */}
              <Box mt={2}>
                <Grid container direction="row">
                  <Box m={1}>
                    <Button
                      endIcon={signUpIcon}
                      disabled={isValid}
                      color="primary"
                      variant="outlined"
                      onClick={signInClicked}>
                      Sign In
                    </Button>
                  </Box>
                </Grid>
              </Box>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Box onClick={() => history.push('signup')}>
                  <Typography className={classes.hover} variant="body2">
                    Register a new account
                  </Typography>
                </Box>
                <Box>
                  <Button color="secondary" variant="text" endIcon={helpIcon} onClick={() => setOpen(true)}>
                    Support
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
      <Grid className={classes.top} xs={12} container direction="row" justifyContent="center" alignItems="center" item>
        <Typography variant="body1" style={{ color: 'white' }}>
          Humanex Ventures 2022 ©
        </Typography>
      </Grid>
      <SupportPopup
        open={open}
        handleClose={() => setOpen(false)}
        status={status}
        setStatus={setStatus}
        openSnackBar={handleOpenSB}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar}
        autoHideDuration={3500}
        onClose={handleCloseSB}
        style={{ width: '40%' }}>
        <Alert onClose={handleCloseSB} sx={{ width: '100%' }} severity={status === 200 ? 'success' : 'error'}>
          {status === 200 ? 'Sent successfully, check your email!' : 'Oops! Something went wrong.'}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default SignIn
