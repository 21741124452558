import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { CircularProgress } from '@material-ui/core'

import { useValidCode, useValidPassword, useValidUsername } from '../../hooks/useAuthHooks'
import { Code, Password, Username } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'

import Logo from '../White_humanex.png'

const useStyles = makeStyles({
  root: {
    height: '100vh',
    backgroundColor: '#444',
  },
  top: {
    padding: '0 2vw',
    marginBottom: '0',
  },
})

export default function ForgotPassword() {
  const classes = useStyles()

  const { code, setCode, codeIsValid } = useValidCode('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')

  const isValid =
    !codeIsValid ||
    code.length === 0 ||
    !usernameIsValid ||
    username.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const history = useHistory()

  const authContext = useContext(AuthContext)

  const resetPassword = async () => {
    setLoading(true)
    try {
      setLoading(false)
      await authContext.forgotPassword(username, code, password)
      setReset(true)
    } catch (err) {
      setLoading(false)
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const updatePassword = (
    <>
      <Box width="80%" m={1}>
        <Code codeIsValid={codeIsValid} setCode={setCode} />
      </Box>
      <Box width="80%" m={1}>
        <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
      </Box>
      <Box width="80%" m={1}>
        <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
      </Box>
      <Box width="80%" m={1}>
        <Password label="Confirm Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} />
      </Box>

      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button onClick={() => history.goBack()} color="secondary" variant="contained">
              Cancel
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isValid} color="primary" variant="outlined" onClick={resetPassword}>
              Change Password
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  const passwordReset = (
    <>
      <Typography variant="h5">{`Password Reset`}</Typography>

      <Box m={4}>
        <Button onClick={() => history.push('/signin')} color="primary" variant="outlined">
          Sign In
        </Button>
      </Box>
    </>
  )

  return (
    <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
      <Grid className={classes.top} xs={12} container direction="row" justifyContent="center" alignItems="center" item>
        <img src={Logo} alt="logo" height="75" />
        <Typography variant="h2" style={{ marginLeft: '15px', color: 'white' }}>
          Recognition
        </Typography>
      </Grid>
      <Grid xs={11} sm={6} lg={4} container direction="row" justify="center" alignItems="center" item>
        <Paper style={{ width: '100%', padding: 16 }}>
          {loading ? (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Grid container direction="column" justify="center" alignItems="center">
              {/* Title */}
              <Box m={3}>
                <Grid container direction="row" justify="center" alignItems="center">
                  <Typography variant="h4">Forgot Password</Typography>
                </Grid>
              </Box>

              {!reset ? updatePassword : passwordReset}
            </Grid>
          )}
        </Paper>
      </Grid>
      <Grid className={classes.top} xs={12} container direction="row" justifyContent="center" alignItems="center" item>
        <Typography variant="body1" style={{ color: 'white' }}>
          Humanex Ventures 2022 ©
        </Typography>
      </Grid>
    </Grid>
  )
}
