import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import { CircularProgress } from '@material-ui/core'
import Alert from '@mui/material/Alert'
import Help from '@mui/icons-material/Help'

import { useValidEmail, useValidPassword, useValidName } from '../../hooks/useAuthHooks'
import { Email, Password, Name, SupportPopup } from '../../components/authComponents'
import Login from '@mui/icons-material/Login'
import Icon from '@material-ui/core/Icon'

import { AuthContext } from '../../contexts/authContext'

import Logo from '../White_humanex.png'

const useStyles = makeStyles({
  root: {
    height: '100vh',
    backgroundColor: '#444',
  },
  top: {
    padding: '0 2vw',
    marginBottom: '0',
  },
})

const SignUp: React.FunctionComponent<{}> = () => {
  const classes = useStyles()

  const { email, setEmail, emailIsValid } = useValidEmail('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const { name, setName, nameIsValid } = useValidName('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [created, setCreated] = useState(false)
  const [open, setOpen] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const handleOpenSB = () => setOpenSnackBar(true)
  const handleCloseSB = () => setOpenSnackBar(false)
  const [status, setStatus] = useState(0)

  const signUpIcon = <Icon component={Login} />
  const helpIcon = <Icon component={Help} />

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')

  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    !nameIsValid ||
    name.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const history = useHistory()

  const authContext = useContext(AuthContext)

  const signInClicked = async () => {
    setLoading(true)
    try {
      await authContext.signUpWithEmail(email, name, email, password)
      setLoading(false)
      setCreated(true)
    } catch (err) {
      setLoading(false)
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const signUp = (
    <>
      <Box width="80%" m={1}>
        <Name nameIsValid={nameIsValid} helperText="This name will be used as your signature when you send recognition." setName={setName} />
      </Box>
      <Box width="80%" m={1}>
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>
      <Box width="80%" m={1}>
        <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
      </Box>
      <Box width="80%" m={1}>
        <Password label="Confirm Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} />
      </Box>
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justifyContent="center">
          <Box m={1}>
            <Button onClick={() => history.goBack()} color="secondary" variant="contained">
              Back
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isValid} endIcon={signUpIcon} color="primary" variant="outlined" onClick={signInClicked}>
              Sign Up
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  const accountCreated = (
    <>
      <Typography variant="h5">{`Created account`}</Typography>
      <Typography variant="h6">{`Verfiy Code sent to ${email}`}</Typography>

      <Box m={4}>
        <Button onClick={() => history.push('/verify')} color="primary" variant="outlined">
          Verify Account
        </Button>
      </Box>
    </>
  )

  return (
    <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
      <Grid className={classes.top} xs={12} container direction="row" justifyContent="center" alignItems="center" item>
        <img src={Logo} alt="logo" height="75" />
        <Typography variant="h2" style={{ marginLeft: '15px', color: 'white' }}>
          Recognition
        </Typography>
      </Grid>
      <Grid xs={11} sm={6} lg={4} container direction="row" justifyContent="center" alignItems="center" item>
        <Paper style={{ width: '100%', padding: 16 }}>
          {loading ? (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              {/* Title */}
              <Box m={3}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Typography variant="h4">Sign Up</Typography>
                </Grid>
              </Box>

              {!created ? signUp : accountCreated}
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Box>
                  <Button color="secondary" variant="text" endIcon={helpIcon} onClick={() => setOpen(true)}>
                    Support
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
      <Grid className={classes.top} xs={12} container direction="row" justifyContent="center" alignItems="center" item>
        <Typography variant="body1" style={{ color: 'white' }}>
          Humanex Ventures 2022 ©
        </Typography>
      </Grid>
      <SupportPopup
        open={open}
        handleClose={() => setOpen(false)}
        status={status}
        setStatus={setStatus}
        openSnackBar={handleOpenSB}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ width: '40%' }}
        open={openSnackBar}
        autoHideDuration={3500}
        onClose={handleCloseSB}>
        <Alert onClose={handleCloseSB} sx={{ width: '100%' }} severity={status === 200 ? 'success' : 'error'}>
          {status === 200 ? 'Sent successfully, check your email!' : 'Oops! Something went wrong.'}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default SignUp
