import React, { useContext, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { Button, Grid, Typography, Box, Icon, Snackbar } from '@material-ui/core'
import Help from '@mui/icons-material/Help'
import Alert from '@mui/material/Alert'
import Paper from '@material-ui/core/Paper'
import Logout from '@mui/icons-material/Logout'
import Send from '@mui/icons-material/Send'
import { CircularProgress } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../contexts/authContext'
import { SupportPopup } from '../components/authComponents'
import PreviewDialog from '../components/previewdialog'

import Logo from './White_humanex.png'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#444',
  },
  top: {
    padding: '0 2vw',
    marginBottom: '5vh',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

function SendRecognition() {
  const classes = useStyles()

  const history = useHistory()

  const auth = useContext(AuthContext)

  const logoutIcon = <Icon component={Logout} />
  const helpIcon = <Icon component={Help} />

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [openSupport, setOpenSupport] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const handleOpenSB = () => setOpenSnackBar(true)
  const handleCloseSB = () => setOpenSnackBar(false)
  const [supportStatus, setSupportStatus] = useState(0)
  const [loading, setLoading] = useState(false)
  const [fetchStatus, setFetchStatus] = useState(false)

  const checkRefresh = async () => {
    const session = await auth.getSession()
    window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
    window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
    if (session && session.isValid()) return
    history.push('/')
  }

  const emojiReg = new RegExp(/[^a-zA-Z0-9\n\s\=`~!@#\$%\^&\*\(\)\_\+\{\}\[\]\\\|\,\.\/\?\;\'\:\"\<\>\u201C\u201D\u2018\u2019\-]/)

  return ( 
    <div>
      <Formik
        initialValues={{
          toName: '',
          toEmail: '',
          submissionText: '',
        }}
        validationSchema={Yup.object({
          toEmail: Yup.string().email('Invalid email address').required('Required'),
          toName: Yup.string().required('Required'),
          submissionText: Yup.string().required('Required').min(1, 'Please provide some recognition!')
          .test("testText", "Submission text can only contain letters, numbers, and special characters.", (submissionText) => {
            return !emojiReg.test(submissionText)
          }),
        })}
        onSubmit={async (values, onSubmitProps) => {
          setLoading(true)
          onSubmitProps.setSubmitting(true)
          try {
            await checkRefresh()
            const response = await fetch('https://recognition.humanexventures.com/recognition', {
              method: 'POST',
              body: JSON.stringify(values),
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                  'Content-Type,access-control-allow-origin, access-control-allow-headers',
                Authorization: 'Bearer ' + auth.sessionInfo.accessToken,
              },
            })

            if (response.ok) {
              setMessage('Recognition Sent!')
              onSubmitProps.setSubmitting(false)
              setFetchStatus('success')
              onSubmitProps.resetForm()
            } else {
              setMessage('Oops, something went wrong! Try Again.')
              onSubmitProps.setSubmitting(false)
              setFetchStatus('error')
            }
            setLoading(false)
          } catch (err) {
            setMessage(err.message)
            onSubmitProps.setSubmitting(false)
            setFetchStatus('error')
            setLoading(false)
          }
          handleOpen()
        }}>
        {({ handleSubmit, isSubmitting, values }) => (
          <div>
            <Form className="SubmissionForm" onSubmit={handleSubmit}>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                style={{ minHeight: '100vh' }}
                className={classes.root}>
                <Grid
                  xs={11}
                  justifyContent="flex-end"
                  container
                  direction="row"
                  alignItems="center"
                  item
                  style={{ marginTop: '1vh' }}>
                  <Button
                    variant="contained"
                    endIcon={logoutIcon}
                    disabled={isSubmitting}
                    onClick={() => {
                      auth.signOut()
                      localStorage.clear()
                      history.push('/')
                    }}>
                    Log out
                  </Button>
                </Grid>
                <Grid
                  className={classes.top}
                  xs={12}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  item>
                  <img src={Logo} alt="logo" height="75" />
                  <Typography variant="h2" style={{ marginLeft: '15px', color: 'white' }}>
                    Recognition
                  </Typography>
                </Grid>
                <Grid xs={12} container direction="row" justifyContent="center" alignItems="center" item>
                  <Paper style={{ padding: 28 }}>
                    {loading ? (
                      <Grid container direction="column" justifyContent="center" alignItems="center">
                        <CircularProgress />
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12} style={{ marginBottom: '2vh' }}>
                          <Typography variant="h6" align="center">
                            To send recognition, please fill out the fields below.
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          xs={12}
                          style={{ marginBottom: '2vh' }}>
                          <Grid item xs={11} sm={9} lg={8} style={{ width: '100%' }}>
                            <Field component={TextField} type="toName" label="Recipient Name" name="toName" fullWidth />
                          </Grid>
                          <br></br>

                          <Grid item xs={11} sm={9} lg={8} style={{ width: '100%' }}>
                            <Field
                              component={TextField}
                              type="toEmail"
                              label="Recipient Email"
                              name="toEmail"
                              fullWidth
                            />
                          </Grid>
                          <br></br>
                          <Grid item xs={11} sm={9} lg={8} style={{ width: '100%' }}>
                            <Field
                              component={TextField}
                              type="submissionText"
                              label="Recognition Message"
                              name="submissionText"
                              variant="outlined"
                              fullWidth
                              multiline
                              maxRows={6}
                              minRows={4}
                            />

                            <Typography
                              variant="body2"
                              color={values.submissionText.length > 650 ? 'error' : 'initial'}>
                              {values.submissionText.length} / 650 Characters <br />
                              {values.submissionText.length > 650 ? 'Message may not display correctly!' : ''}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          direction="column"
                          justifyContent="space-between"
                          alignItems="center"
                          xs={12}>
                          <Grid
                            container
                            direction="row"
                            display="center"
                            justifyContent="space-evenly"
                            alignItems="center"
                            style={{ width: '100%', padding: '10px' }}>
                            <PreviewDialog formValues={values} />
                            <Button endIcon={<Send />} color="primary" variant="outlined" type="submit">
                              Send
                            </Button>
                            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                          </Grid>
                          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                            <Box>
                              <Button
                                color="secondary"
                                variant="text"
                                endIcon={helpIcon}
                                onClick={() => setOpenSupport(true)}>
                                Support
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Paper>
                </Grid>
                <Grid
                  className={classes.top}
                  xs={12}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginTop: '5vh' }}
                  item>
                  <Typography variant="body1" style={{ color: 'white ' }}>
                    Humanex Ventures 2022 ©
                  </Typography>
                </Grid>
              </Grid>
            </Form>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={open}
              style={{ width: '40%' }}
              autoHideDuration={3500}
              onClose={handleClose}>
              <Alert
                onClose={handleClose}
                sx={{ width: '100%' }}
                severity={fetchStatus === 'success' ? 'success' : 'error'}>
                {message}
              </Alert>
            </Snackbar>
            <SupportPopup
              open={openSupport}
              handleClose={() => setOpenSupport(false)}
              status={supportStatus}
              style={{ width: '40%' }}
              setStatus={setSupportStatus}
              openSnackBar={handleOpenSB}
            />
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={openSnackBar}
              fullWidth
              autoHideDuration={3500}
              onClose={handleCloseSB}>
              <Alert
                onClose={handleCloseSB}
                sx={{ width: '100%' }}
                severity={supportStatus === 200 ? 'success' : 'error'}>
                {supportStatus === 200 ? 'Sent successfully, check your email!' : 'Oops! Something went wrong.'}
              </Alert>
            </Snackbar>
          </div>
        )}
      </Formik>
    </div>
  )
}

export default SendRecognition
